import { React } from "react";
import Gallery from "react-photo-gallery";
//import { pictures } from "./pages/Pictures";
import picture1 from '../assets/gallery/main_1.jpg';
import picture2 from '../assets/gallery/main_1.jpg';
import picture3 from '../assets/gallery/main_1.jpg';
import picture4 from '../assets/gallery/main_1.jpg';
import picture5 from '../assets/gallery/main_1.jpg';




const pictures = [
  {
    src: picture1,
    width: 3,
    height: 2
  },
  {
    src: picture2,
    width: 3,
    height: 2
  },
  {
    src: picture3,
    width: 6,
    height: 2
  },
  {
    src: picture5,
    width: 6,
    height: 2
  },
  {
    src: picture4,
    width: 4,
    height: 3
  },
 
  {
    src: "https://source.unsplash.com/NQSWvyVRIJk/800x599",
    width: 4,
    height: 3
  },
  {
    src: "https://source.unsplash.com/zh7GEuORbUw/600x799",
    width: 3,
    height: 4
  },
  {
    src: "https://source.unsplash.com/PpOHJezOalU/800x599",
    width: 4,
    height: 3
  },
  {
    src: "https://source.unsplash.com/I1ASdgphUH4/800x599",
    width: 4,
    height: 3
  },
  {
    src: "https://source.unsplash.com/XiDA78wAZVw/600x799",
    width: 3,
    height: 4
  },
  {
    src: "https://source.unsplash.com/x8xJpClTvR0/800x599",
    width: 4,
    height: 3
  },
 
 ];

const GalleryL = () => {
  return (
    <div className="mt-8">     
      <Gallery photos={pictures} />
    </div>
  );
}

export default GalleryL;
