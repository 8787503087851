import React from 'react';
import Navbar from '../Navbar';
import BackgroundH from '../../assets/blue-night-cosmos-sky-background.jpg';
import Video from '../../assets/video/hero24fps.mp4';
import { BsArrowDownShort } from 'react-icons/bs';
import { Link } from 'react-scroll';
import Single from '../../assets/gallery/main_3.jpg'
import Double from '../../assets/gallery/main_2.jpg'
import Triple from '../../assets/gallery/1.jpg'
import Four from '../../assets/gallery/main_4.jpg'
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

const Hero = () => {
  return (
    
    <div class="relative flex h-screen overflow-hidden ">

<Navbar />



<div class=" z-30 mt-[-1rem]  h-screen w-screen text-center mx-auto flex flex-col justify-center item-center absolute">

<h1 className='md:text-7xl sm:text-6xl text-4xl font-bold md:py-6 text-[#F1F1F1] drop-shadow-lg'>
          Cosmo Game
        </h1>
        <div className='flex justify-center items-center '>
          <p className='md:text-4xl sm:text-4xl text-xl font-bold py-4 text-white-500 text-[#E48109] drop-shadow-lg'>
            <a href="https://t.me/cosmo_core_game_bot" target = "_blank">Play Now</a>
          </p>         
        </div>
        <br />
       
       
     
  
  
 {/*  <div class="relative z-30 p-5 text-2xl text-white bg-purple-300 bg-opacity-50 rounded-xl">
    Welcome to my site!
  </div> */}
  </div>

 
  <div  className="absolute">
   <img src={BackgroundH} className=" z-10 w-auto min-w-full min-h-full max-w-none object-none h-1024 w-1980"/>
  </div>
</div>
  );
};

export default Hero;
