import { React } from "react";
import Roadmap from './pages/Roadmap';
import Works from './pages/Works';
import Footer from './pages/Footer';
import Hero from './pages/Hero';
import Network from './pages/Network';
import Team from './pages/Team';


function Home() {
  return (
    <div>     
      <Hero />
      {/* <Works /> */}
     
    </div>
  );
}

export default Home;
