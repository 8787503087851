import { React } from "react";

import Home from './components/Home';
import GalleryL from './components/GalleryL';
import Application from './components/Application';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";



function App() {
  return (
    <div>     
   
      <Router>
                  
        <Routes>         
            <Route exact path="/" element={<Home/>} />    
            <Route exact path="/gallery" element={<GalleryL/>} /> 
            <Route exact path="/app" element={<Application/>} /> 
        </Routes>  
      </Router> 
    </div>
    
  );
}

export default App;
