import React, { useState } from 'react';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import Logo from '../assets/logo_85x85.png';
import TelegramRu from '../assets/telegram_ico_ru.png';
import TelegramEng from '../assets/telegram_ico_eng.png';
import Pdf from '../assets/Litepaper_Cosmo_Game.pdf';
import { 
  Link
} from "react-router-dom";
import { 
  FaTelegram 
} from 'react-icons/fa';
import {
  BsDiscord
} from 'react-icons/bs';
import { IoGameControllerSharp } from "react-icons/io5";
import { FaTwitter } from "react-icons/fa";

const Navbar = () => {
  const [nav, setNav] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };

  return (
    <div className='flex items-center w-screen h-24 max-w-[1240px] mx-auto px-4 text-[#F1F1F1] z-40'>
      
                 
      
      <ul className='hidden md:flex ml-4 text-xl'>
        <li className='p-4 cursor-pointer'><Link to="/">Home</Link></li>
        <li className='p-4 cursor-pointer'><a href = {Pdf} target = "_blank">Docs</a></li>       
        <li className='p-4 cursor-pointer'><a href="https://nft.cosmocore.xyz" target = "_blank">NFT</a></li>
        
      </ul>
      <div className='flex justify-start items-center h-24 max-w-[1240px] mx-auto px-4 text-white'></div>
      <ul className='hidden md:flex right-0'>
      <li className='p-4 cursor-pointer'><a href='https://x.com/cosmo_core_game' target = "_blank"><FaTwitter size={28} /></a></li>
      <li className='p-4 cursor-pointer'><a href='https://t.me/cosmo_core_game' target = "_blank"><img className='mx-auto rounded-lg' src={TelegramRu} alt="/" /></a>    </li>
      <li className='p-4 cursor-pointer'><a href='https://t.me/cosmo_core_game_eng' target = "_blank"><img className='mx-auto rounded-lg' src={TelegramEng} alt="/" /></a>    </li>    
        <li className='p-4 mt-[1px] cursor-pointer'><a href='https://t.me/cosmo_core_game_bot' target = "_blank"><IoGameControllerSharp size={28} /></a></li>
      </ul>
      <div onClick={handleNav} className='block md:hidden'>
          <AiOutlineMenu size={20} />
      </div>
      <ul className={nav ? 'fixed left-0 top-0 w-[40%] h-full bg-slate-50 ease-in-out duration-500 text-[#555]' : 'ease-in-out duration-500 fixed left-[-100%] text-[#555]'}>
       
        <div onClick={handleNav} className='block absolute top-3 right-0 h-16 w-8'><AiOutlineClose size={20}/></div>

          <li className='p-4 border-b border-gray-300 font-bold'><Link to="/">Home</Link></li>
          <li className='p-4 border-b border-gray-300 font-bold'><a href = {Pdf} target = "_blank">Docs</a></li>
          <li className='p-4 border-b border-gray-300 font-bold'><a href="https://t.me/cosmo_core_game_bot" target = "_blank">Game</a></li>
          <li className='p-4 border-b border-gray-300 font-bold'><a href="https://x.com/cosmo_core_game" target = "_blank"><FaTwitter size={28} /></a></li>
          <li className='p-4 cursor-pointer border-b border-gray-300'><a href='https://t.me/cosmo_core_game' target = "_blank"><img className='rounded-lg' src={TelegramRu} alt="/" /></a>    </li>
         <li className='p-4 cursor-pointer border-b border-gray-300'><a href='https://t.me/cosmo_core_game_eng' target = "_blank"><img className=' rounded-lg' src={TelegramEng} alt="/" /></a>    </li>   
          <li className='p-4 border-b border-gray-300 font-bold'><a href="https://t.me/cosmo_core_game_bot" target = "_blank"><IoGameControllerSharp size={28} /></a></li>
         
      </ul>

      
     
    </div>
  );
};

export default Navbar;
