import React from 'react';

const App = () => {
  return (
    <div className='text-white bg-gradient-to-b from-emerald-800 to-emerald-700' name='home'>
      <div className='max-w-[1200px] mt-[-96px] w-full h-screen mx-auto text-center flex flex-col justify-center'>
        <h1 className='md:text-7xl sm:text-6xl text-4xl font-bold md:py-6'>
          Application is progress...
        </h1>
        <div className='flex justify-center items-center'>
          <p className='md:text-4xl sm:text-4xl text-xl font-bold py-4 text-orange-500'>
             Stay tuned!
          </p>         
        </div>
        <br />  
        
      </div>
    </div>
  );
};

export default App;
